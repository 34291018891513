<template lang="pug">
div
  van-form.col
    van-field(v-model='password', clearable type='password', name='旧密码', label='旧密码', placeholder='旧密码', :rules="[{ required: true, message: '请填写旧密码' }]")
    van-field(v-model='newpassword', clearable type='password', name='新密码', label='新密码', placeholder='新密码', :rules="[{ required: true, message: '请填写新密码' }]")
    van-field(v-model='renewpassword', clearable type='password', name='确认新密码', label='确认新密码', placeholder='确认新密码', :rules="[{ required: true, message: '请填写确认新密码' }]")
    van-row.row
      van-button.col(type='info' native-type='submit' block round @click='onSubmit' :disabled='commitState') 修改密码

</template>

<script>

export default {
  data() {
    return {
      commitState: false,
      password: '',
      newpassword: '',
      renewpassword: ''
    }
  },
  methods: {
    async onSubmit() {
      if(this.newpassword === this.password){
        this.$toast('新旧密码相同')
      } else if(this.newpassword != this.renewpassword){
        this.$toast('两次密码输入不一致')
      } else if([...this.newpassword].length < 6) {
        this.$toast('密码长度不能少于6位')
      } else {
        let param = {old_password:this.password,new_password:this.newpassword}
        try {
          this.commitState = true
          await this.$api.ResetPassword(param)
          this.$router.push({
            name: 'person'
          })
        } catch(e) {
          this.$toast(this.$error(e))
        }
        this.commitState = false
      }
    },
  },
  async mounted() {
    this.$emit('on-nav', {
      leftText: '返回',
      leftArrow: true,
      onClickLeft: () => {
        this.$router.go(-1)
      }
    })
  }
}
</script>

<style lang="less" scoped>
.row {
  margin: 0px 10px;
}
.col {
  margin: 10px 0px;
}
</style>